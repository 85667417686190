<template>
  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12" class="p-3">
    <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3">
        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3" v-if="is_god == 1">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
            <p>สถานะ</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
            <v-select
              v-model="mail_status"
              placeholder="กรุณาเลือก"
              label="text"
              :options="status_list"
              :searchable="false"
              hide-no-data
                />
            <!-- <vs-select v-model="mail_status" placeholder="สถานะ">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in status_list" />
            </vs-select> -->
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3"  v-if="mail_status_validate == 1">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
            <p>เวลาส่งรายงาน</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
            <vs-input
              type="time"
              v-model="time_to_send"
              label=""
            />
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3" v-if="mail_status_validate == 1">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
            <p>ส่งรายงานไปยังอีเมล</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
            <vs-input v-model="send_to" placeholder="" />
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3" v-if="mail_status_validate == 1" :key="index_list_fields" v-for="(item_list_fields,index_list_fields) in list_fields">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
            <p>เพิ่มคอลัมน์รายงาน {{index_list_fields+1}}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3" >
            <v-select
              v-model="list_fields[index_list_fields]"
              placeholder="กรุณาเลือก"
              label="text"
              :options="list_additional_field"
              :searchable="false"
              hide-no-data
              @input="handleSelectFields()"
                />
          </vs-col>
        </vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8"  v-if="mail_status_validate == 1 || is_god == 1">
          <vs-button color="primary" type="relief" @click="saveSetting()">บันทึก</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" v-if="mail_status_validate == 0 && is_god == 0">
              <p>กรุณาติดต่อเจ้าหน้านี้เพื่อเปิดการใช้งาน โทร 02 821 5799</p>
          </vs-col>
    </vs-row>
  </vs-col>
</template>
<style>
 p {
   font-size: 15px;
 }
 .v-select {
  width: 78% !important;
  font-size: 15px;
}
.v-select .selected-tag {
  position: absolute;
}
.v-select input.form-control {
  width: 100% !important;
}
</style>
<script>

import Vue from "vue";
import axios from "axios";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
// import VueTimepicker from 'vue2-timepicker';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from "vue-select";

console.log("lang", lang);


Vue.use(VueCryptojs);

Vue.component("customer-header", CustomerHeader);

moment.locale("th");
export default {
  name: "KvisitSetting",
  components: {
    Datepicker,
    VueTimepicker,
    VueCookies,
    "v-select": vSelect,
  },
  data() {
    return {
      id: null,
      is_god: "0",
      customer_name: "",
      time_to_send: "",
      send_to: "",
      selectedTime: null,
      optional_additional_field: null,
      flatpickrOptions: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true, // optional
        // Other Flatpickr options can be added here
      },
      mail_status:0,
      mail_status_validate:0,
      status_list: [
        { text: "ไม่ทํางาน", value: 0 },
        { text: "ทํางาน", value: 1 },
      ],
      list_additional_field_disabled:["car_type","addition_in_attach_car","visit_objective_type","license_plate_id","contact_address_name","date_in","date_out","addition_in_attach_idcard","addition_out_attach_photo","addition_in_attach_photo"],
      list_additional:[],
      temp_additional_field:[],
      list_additional_field: [],
      list_fields: [],


    }
  },
  methods: {
    dbRef(path) {
          const customerName = $cookies.get("customer");
          const _ducumentPath = `customer/${customerName}${path}`;
          return db.collection(_ducumentPath);
      },
      async getData() {
       await this.dbRef('/autoreport').doc('setting').onSnapshot((doc) => {
          if (doc.exists) {

            this.time_to_send = doc.data().send_time;
            this.send_to = doc.data().send_to;

            if (typeof doc.data().auto_mail === 'undefined'){
              this.mail_status_validate = false
            } else {
              this.mail_status_validate = doc.data().auto_mail;
            }

            this.list_fields = JSON.parse(doc.data().addition_fields);
          }
        });
      },
      async getAddtionalField() {
        // this.list_additional_field = [];
        const customerName = $cookies.get("customer");
        const _ducumentPath = `customer/${customerName}`;
        const document = await db.doc(_ducumentPath).get()
        if (document.exists) {
          const data = document.data();

          const data_array = JSON.parse(data.addition_fields_report);
          const addition_fields = JSON.parse(data.addition_fields);

          console.log('-------------- addition fields -----------------');
          console.log(addition_fields);

          await this.merge_data(data_array);
          await this.merge_data(addition_fields.fields_in);
          await this.merge_data(addition_fields.fields_out);

          await this.mapping_data_list();

          if (data.enable_fee){
            const item = {text:"ค่าจอด", value:"enable_fee"};
            // this.list_additional_field.push(item);
            this.list_additional.push(item);

          }

          if (data.enable_card_missing) {
            const item = {text:"บัตรหาย", value:"card_missing"};
            // this.list_additional_field.push(item);
            this.list_additional.push(item);
          }

          console.log(this.list_fields);
          console.log(this.list_additional);
          this.list_additional.forEach(element => {
            const data = this.list_fields.find(x => x.value === element.value);
            if (typeof data === 'undefined') {
              this.list_additional_field.push(element);
            }
          })

          if (this.list_fields.length < 6) {
            this.list_fields.push(null);
          }
        }

      },
      async mapping_data_list() {
        // console.log(this.list_fields)
        console.log('mapping_data_list')
        console.log(this.temp_additional_field)
        for (const [key, value] of Object.entries( this.temp_additional_field)) {
          console.log(`${key}: ${value}`);
          if (this.list_additional_field_disabled.includes(key) === false) {
            const item = {text:value, value:key};
            this.list_additional.push(item);
          }
        }
      },
      handleSelectMailStatus() {
        this.mail_status_validate = this.mail_status.value;
      },
      async handleSelectFields() {
        this.list_fields = this.list_fields.filter(item => item !== null);
        this.list_additional_field = [];
        this.list_additional.forEach(element => {
            const data = this.list_fields.find(x => x.value === element.value);
            if (typeof data === 'undefined') {
              this.list_additional_field.push(element);
            }
          })
        if (this.list_fields.length < 6) {
              this.list_fields.push(null);
        }


      },
      async merge_data(data) {
        data.forEach(element => {
            if (element.report_field !== ""){

              this.temp_additional_field[element.valueField] = element.report_field
            }
          })
      },
      async saveSetting(){
        // this.$swal('Hello Vue world!!!');

        this.list_fields = this.list_fields.filter(item => item !== null);
        const list_fields_data = this.list_fields.filter(item => item !== null);

        let data = {};
        data.auto_mail = this.mail_status.value;

        if(this.isNotEmptyData(this.send_to))
        {
          data.send_to = this.send_to;
        }

        if (this.isNotEmptyData(this.time_to_send)){
          data.send_time = this.time_to_send;
        }

        if (this.isNotEmptyData(list_fields_data)){
          data.addition_fields = list_fields_data;
        }
        const doc_kvisit = await this.dbRef('/autoreport').doc('setting').get();
        if (doc_kvisit.exists) {
          await this.dbRef('/autoreport').doc('setting').update(data)
        } else {
          await this.dbRef('/autoreport').doc('setting').set(data)

        }

        if (this.list_fields.length === 0 || this.list_fields.length < 6) {
          this.list_fields.push(null);
        }

        Swal.fire({
          title: '',
          text: 'บันทึกสําเร็จ',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        })
      },
        isNotEmptyData(data) {
          if (data == undefined) {
            return false;
          }

          if (data == null) {
            return false;
          }

          if (data == "") {
            return false;
          }

          return true;
        }
  },
  computed: {
    validName() {
          return true;
      },
  },
  async created() {
    this.is_god = $cookies.get("is_god");
    await this.getData();
    await this.getAddtionalField();
    this.mail_status = this.status_list.filter(item => item.value === this.mail_status_validate)[0];
    // await this.getDataCreate();
        // this.mail_status = this.status_list.filter(item => item.value === this.mail_status)[0];
  }
}
</script>
