<template>
  <div class="row justify-content-center">
  <customer-header />
    <vs-card style="margin-top: 10px">
      <vs-row vs-type="flex" vs-justify="center" class="vx-row" v-if="!defaultPage">
        <vs-col vs-type="flex" vs-justify="center" vs-w="2">
          <vs-list>
            <vs-list-item @click="page = 1" v-if="kscansetting">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                <a href="#" class="nav-link flex items-center" :class="{ 'active': page === 1 }" target="_self" @click="page = 1"><span class="truncate">รายงานข้อมูลการเดินตรวจ</span></a>
              </vs-col>
            </vs-list-item>
            <vs-list-item @click="page = 2" v-if="kvisitsetting">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                <a href="#" class="nav-link flex items-center" :class="{ 'active': page === 2 }"  target="_self" @click="page = 2"><span class="truncate">รายงานข้อมูลรถเข้า-ออก</span></a>
              </vs-col>
            </vs-list-item>
          </vs-list>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="10" class="p-3">
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3 row-from" v-if="page == 1">
            <KscanSetting/>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3 row-from" v-else-if="page == 2">
            <kvisitsetting/>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3 row-from" v-else-if="page == 3">
          </vs-row>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center" class="vx-row" v-else>
        <vs-col vs-type="flex" vs-justify="center" vs-w="12">
          <label>ไม่พบข้อมูล</label>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<style>
.row-from {
  border-left: 1px solid #3f4652;
  /* border-radius: 10px; */
}
a span {
  font-size: 15px !important;
}

.nav-link.active {
  border-radius: 5px;
  padding: 7px;
  background: linear-gradient(118deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), 0.7)) !important;
  color: #fff; /* Adjust text color for better visibility */
}
</style>
<script>
import Vue from "vue";
import axios from "axios";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import KscanSetting from "./KscanSetting.vue";
import kvisitsetting from "./KvisitSetting.vue";
import { db, firebaseApp } from "../store/firebaseDb";

export default {
  components: {
    KscanSetting,
    kvisitsetting
  },
  methods: {

    dbRef() {
            const customerName = $cookies.get("customer");
            const _ducumentPath = `customer`;
            return db.collection(_ducumentPath);
        },
  },
  data() {
    return {
      page:1,
      kscansetting:false,
      kvisitsetting:false,
      defaultPage:true,


    }
  },
  computed: {

  },
  async created() {
    console.log('------ main ---------');
    const customerName = $cookies.get("customer");
    const doc_kscan = await db.collection(`customer/${customerName}/scan`).doc("data").get();
    if (doc_kscan.exists){
      console.log('------ main kscan ---------');
      console.log(doc_kscan.data());
      this.kscansetting = true;
      this.defaultPage = false;
    }

    if (!this.kscansetting) {

      this.page = 2;
    }

    const doc = await db.collection(`customer/${customerName}/transactions`).get();

    console.log('test data');
    doc.forEach((doc) => {
      if (doc.exists) {
        this.kvisitsetting = true
        // console.log(doc.id, "=>", doc.data());
      }
    })

  }
}
</script>
