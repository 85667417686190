<template>
    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12" class="p-3">
      <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3">
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3" v-if="is_god == 1">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
              <p>สถานะ</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8" >
              <vs-select v-model="mail_status" placeholder="สถานะ">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in status_list" />
              </vs-select>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3" v-if="mail_status == 1">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
              <p>เวลาส่งรายงาน</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4" v-if="mail_status == 1">
              <vs-input
                type="time"
                v-model="time_to_send"
                label=""
              />
            </vs-col>
          </vs-row>

          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="p-3" v-if="mail_status == 1">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="4" class="px-5">
              <p>ส่งรายงานไปยังอีเมล</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
              <vs-input v-model="send_to" placeholder="" />
            </vs-col>
          </vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" v-if="mail_status == 1 || is_god == 1">
            <vs-button color="primary" type="relief" @click="saveSetting()">บันทึก</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" v-if="mail_status == 0 && is_god == 0">
              <p>กรุณาติดต่อเจ้าหน้านี้เพื่อเปิดการใช้งาน โทร 02 821 5799</p>
          </vs-col>
      </vs-row>
    </vs-col>
</template>
<script>

import Vue from "vue";
import axios from "axios";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
// import VueTimepicker from 'vue2-timepicker';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

console.log("lang", lang);


Vue.use(VueCryptojs);

Vue.component("customer-header", CustomerHeader);

moment.locale("th");
  export default {
    name: "KscanSetting",
    components: {
      Datepicker,
      VueTimepicker,
      VueCookies
    },
    methods: {
      dbRef() {
            const customerName = $cookies.get("customer");
            const _ducumentPath = `customer/${customerName}/scan`;
            return db.collection(_ducumentPath);
        },
        async getData() {
          this.dbRef().doc('data').onSnapshot((doc) => {
            if (doc.exists) {
              console.log(doc.data());
              this.time_to_send = doc.data().send_time;
              this.send_to = doc.data().send_to;
              if (typeof doc.data().auto_mail == 'undefined'){
                this.mail_status = false
              } else {
                this.mail_status = doc.data().auto_mail;
              }
            }
          });
        },
        async saveSetting(){
          Swal.fire({
            title: '',
            text: 'บันทึกสําเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง'
          })
          console.log('test')
          let data = {};
          //   send_to:this.send_to,
          //   send_time:this.time_to_send,
          //   auto_mail:this.mail_status
          // };
          if (this.isNotEmptyData(this.send_to)){
            data.send_to = this.send_to;
          }

          if (this.isNotEmptyData(this.time_to_send)){
            data.send_time = this.time_to_send;
          }

          data.auto_mail = this.mail_status;


          console.log(data);

          await this.dbRef().doc('data').update(data);
        },
        isNotEmptyData(data) {
          if (data == undefined) {
            return false;
          }

          if (data == null) {
            return false;
          }

          if (data == "") {
            return false;
          }

          return true;
        }
    },
    data() {
      return {
        id: null,
        is_god: "0",
        customer_name: "",
        star_time: "",
        end_time: "",
        time_to_send: "",
        send_to: "",
        startDateReport: "",
        selectedTime: null,
        flatpickrOptions: {
          enableTime: true,
          noCalendar: true,
          // dateFormat: 'hh:ii',
          time_24hr: true, // optional
          // Other Flatpickr options can be added here
        },
        mail_status:0,
        status_list: [
          { text: "ไม่ทํางาน", value: 0 },
          { text: "ทํางาน", value: 1 },
        ]
      }
    },
    computed: {
      validName() {
            return true;
        },
    },
    created() {
    this.is_god = $cookies.get("is_god");
      this.getData();
    }
  }
</script>
